var Site = {
    mobile_main_breakpoint: 1040,
    grid: {
        xs: 480,
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1236
    }
};

var GTMEvents = GTMEvents || {};

Site.init = function () {
    Site.rwd();

    Site.refresh();
    //Site.showCookiesPolicy();

    //scrolling elements to specific selector up/down
    $('[data-scrollto]').off('click').on('click', function(e) {
        e.preventDefault();

        var selector = $($(this).data('scrollto'));
        if(selector.get(0)) {
            $('html, body').animate({
                scrollTop: selector.offset().top - $('#header').outerHeight(true)
            }, 750);
        }
    });

    Site.initUnitegallery();
    
    $(window).on('resize.site', function () {
        waitForFinalEvent(
            function () {
                Site.refresh();
                Site.rwd();
            },
            500,
            'initSite'
        );
    });

    //scroll-top button behavior
    var scroll_top_button = $('#js-scroll-top .icon');
    scroll_top_button.hide();
    var scroll_top_timeout;
    $(window).on('scroll', function() {
        if($(window).scrollTop() > $(window).height() * 1.2) {
            scroll_top_button.not(':animated').stop().clearQueue().fadeIn(250);
        } else {
            scroll_top_button.stop().clearQueue().fadeOut(250);
        }
        clearTimeout(scroll_top_timeout);
        scroll_top_timeout = setTimeout(function() {
            scroll_top_button.stop().clearQueue().fadeOut(250);
        }, 7000);
    });

};
Site.ratingStars = function() {
    $('.rating-stars').rateYo({
        starWidth: "17px",
        spacing: "3px",
        normalFill: "#cccccc",
        ratedFill: "#ffcc66",
        readOnly: true
    });
};
Site.refresh = function () {
    Site.setCurrencyActions();
    Site.setLanguageActions();
    Site.setTouchClass();
    Site.styleForms();
    Site.setPaginationLabels();
    if (screenUp('lg')) {
        Site.initMenuDesktop(); 
    } else {
        Site.initMenuMobile(); 
    }
    Site.scrollToHash();
    Site.scrollToID();
    Site.autocomplete();
    Site.newsletter();
    Site.tooltipInit();
    Site.headerSticky();
    Site.headerSearch();
    
    $('#header').hover(
        function(){
            $(this).addClass('hover');
        },
        function(){
            $(this).removeClass('hover');
        }
    );
    
    //tooltipy

    //alert dialogs
    $("[data-alert]:not(.js-init)").off('click').on('click', function (e) {
        e.preventDefault();
        $(this).addClass('js-init');
        Site.alert($(this).data('alert'));
    });


    //confirmation dialogs
    $("[data-confirm]:not(.js-init)").off('click').on('click', function (e) {
        e.preventDefault();
        $(this).addClass('js-init');
        if ($(this).data('callback')) {

            var callback_str = $(this).data('callback')
                + "("
                + ($(this).data('params') ? '"' + $(this).data('params') + '"' : '')
                + ");";

            Site.confirm($(this).data('confirm'), Function(callback_str));
        } else if ($(this).data('location-href')) {
            Site.confirm($(this).data('confirm'), false, $(this).data('location-href'));
        }
    });

    //elementy zmieniające klasę na mouseover/out
    $('.hooverable,.hoverable')
        .off('mouseover')
        .off('mouseout')
        .on('mouseover', function () {
            $(this).removeClass('mouseout');
            $(this).addClass('mouseover');
        })
        .on('mouseout', function () {
            $(this).removeClass('mouseover');
            $(this).addClass('mouseout');
        });

    $('img.lazyload').lazyload({
        threshold: 200,
        skip_invisible: false,
        failure_limit: 50
    });

    this.ratingStars();

    $('.featherlight-gallery').featherlightGallery();

    $('.equalize-cols').each(function () {
        var t = $(this);
        var items = t.data('equalize-item');
        var minWidth = t.data('equalize-min-width');

        if (items) {
            var itemsClass = items.split(';');
            setTimeout(
                function () {
                    for (var i in itemsClass) {
                        if (minWidth && screenDown(minWidth)) {
                            t.find(itemsClass[i]).not('.no-equalize-cols').css('height', 'auto');
                        } else {
                            t.find(itemsClass[i]).not('.no-equalize-cols').equalizeCols();
                        }
                    }
                },
                500
            );
        }

    });

    $('.dropdown-faq ul li a').off('click').on('click', function () {
        var isActive = $(this).parent().is('.active');

        $(this).parents('.dropdown-faq').find('li').removeClass('active');
        $(this).parents('.dropdown-faq').find('li div.wysiwyg:visible').slideUp(500);

        if (!isActive) {
            $(this)
                .parent()
                .addClass('active')
                .end()
                .next()
                .slideToggle(500)
                .end()
            ;
        }
    });
    
    $('.parallax-window').parallaxie();    
    $('.arrow-down-link')
        .off('click')
        .on('click', function(e){
            e.preventDefault();
            var targetId = $(this).attr('href');
            $('html, body').animate({
                scrollTop: $(targetId ? targetId : '.visual-arrow-down-target').offset().top
            }, 1000);
        })
    ; 
    
    $owlVisual = $('#visual .owl-carousel')
        .owlCarousel({
            items: 1,
            startPosition: 1,
            loop: true,
            nav : true, // Show next and prev buttons,
            navText : ["<span></span>","<span></span>"],
            autoplay: true,
            autoHeight: true,
            autoplaySpeed: 1000,
            autoplayTimeout: 7000,
            navSpeed: 1000,
            dotsSpeed: 1000,
            dots: true,
            onInitialized: function(event){
                $(event.target)
                    .addClass('owl-my-loaded')
                ;
            },
            onChanged: function(event){
                
            }
        })
    ;
};

Site.lastScrollTop = 0;
Site.headerSticky = function(){
    $('body').removeClass('header-sticky');
    $(window).on('scroll', function() {
        if (screenUp('md')){
            if ($('#header-search .search-form .ui-menu').is(':visible')){
                $('body').removeClass('header-sticky');
            } else {
                var st = $(this).scrollTop();
                if (st <= 10){
                    $('body').removeClass('header-sticky');
                } else if (st < Site.lastScrollTop) {
                    $('body').addClass('header-sticky');
                }
                Site.lastScrollTop = st;
            }
        } else {
            
        }
    });
};

Site.setTouchClass = function(){
    if (Site.isTouchDevice()) {
        $('body').addClass('touch');
    } else {
        $('body').removeClass('touch');
    }
};

Site.setPaginationLabels = function () {
    var prev = $(".pagination .button-prev a").length > 0 ? $(".pagination .button-prev a") : null;
    var next = $(".pagination .button-next a").length > 0 ? $(".pagination .button-next a") : null;
    if (screenDown('md')) {
        if (prev !== null) {
            prev.html(prev.html().replace('Poprzednia strona', 'Cofnij'));
        }
        if (next !== null) {
            next.html(next.html().replace('Następna strona', 'Dalej'));
        }
    } else {
        if (prev !== null) {
            prev.html(prev.html().replace('Cofnij', 'Poprzednia strona'));
        }
        if (next !== null) {
            next.html(next.html().replace('Dalej', 'Następna strona'));
        }
    }
};

Site.initMenuDesktop = function(){
    $('#header .menu-mobile')
        .remove()
    ;
    $('#menu-left,#menu-right,#infolinia')
        .show();
    ;
};

Site.initMenuMobile = function(){    
    var delay = 300;
    $('#header .container')
        .find('.menu-mobile')
            .remove()
            .end()
        .find('.menu-close')
            .remove()
            .end()
        .append(
            $('<a>')
                .attr('href','#')
                .addClass('icon menu-mobile')
                .off('click')
                .on('click', function(e){
                    e.preventDefault();
                    $('#menu-left,#menu-right')
                        .slideDown(delay);
                    ;
                    $(this).hide();
                    $('#header .menu-close').show();
                    $('#menu-right').css({
                       'top' :  $('#menu-left nav >ul >li').height() + 5
                    });
                    return false;
                })
        )
        .append(
            $('<a>')
                .attr('href','#')
                .addClass('icon menu-close')
                .off('click')
                .on('click', function(e){
                    e.preventDefault();
                    $('#menu-left,#menu-right')
                        .slideUp(delay);
                    ;
                    $(this).hide();
                    $('#header .menu-mobile').show();
                    return false;
                })
                .hide()
        )
    ;
};

Site.alert = function (message) {
    var html = '<p>' + message + '</p>'
        + '<div class="buttons">'
        + '<a href="#" class="ui-button-layer cancel">OK</a>'
        + '</div>';
    var body = $(html);
    return Site.overlay(body, 'alert');
};

Site.confirm = function (message, callback, href, buttons_settings) {
    var buttons_default = {
        save: 'OK',
        cancel: 'Anuluj'
    };

    var buttons = $.extend(buttons_default, buttons_settings);

    var html = '<p>' + message + '</p>'
        + '<div class="buttons">'
        + '<a href="#" class="ui-button-layer cancel">' + buttons.cancel + '</a>'
        + '<a href="#" class="ui-button-layer save">' + buttons.save + '</a>'
        + '</div>';
    var body = $(html);
    $('.save', body).on('click', function (e) {
        e.preventDefault();
        if (callback) {
            callback();
        }
        if (href) {
            window.location.href = href;
        }
    });
    return Site.overlay(body, 'confirm');
};

Site.overlay = function (body, overlay_class) {
    Site.buttonLoader();
    var html = $('<div class="overlay' + (overlay_class ? ' overlay-' + overlay_class : '') + '">\n' +
        '<div class="overlay-content">\n' +
        '<span class="overlay-close icon remove"></span>\n' +
        '<div class="content"></div>' +
        '</div>\n' +
        '</div>');
    $('.content', html).append(body);
    html.hide();
    html.fadeIn(150);
    var close_buttons = $('.overlay-close, .cancel', html);
    close_buttons.on('click', function (e) {
        e.preventDefault();
        html.trigger('closeEnd');
    });
    $('body').append(html);

    $('select', html).select2({
        "language": "pl",
        "minimumResultsForSearch": Infinity
    });


    //
    // html.on('close', function(e) {
    //     html.trigger('closeEnd');
    // })

    html.on('closeEnd', function (e) {
        html.trigger('close');
        $(this).closest('.overlay').stop().clearQueue().fadeOut(150, function () {
            $(this).remove();
        });
    });

    html.on('refresh', function (e) {
        var content = $('.overlay-content', this);
        var top = ($(window).height() - content.height()) / 2;
        content.css({
            'marginTop': top
        });
    });

    $(document).on('keyup.overlay', function (e) {
        if (e.which == 27) {
            html.trigger('closeEnd');
        }
    });

    return html;


};

Site.overlay.close = function () {
    $(document).off('keyup.overlay');
    $('.overlay').stop().clearQueue().fadeOut(150, function () {
        $(this).remove();
    });
};

Site.styleForms = function () {
    //preloader na buttonie po kliknięciu
    $('button[data-preloader],a[data-preloader]').on('click.preloader', function (e) {
        Site.buttonLoader($(this));
    });

    $('.ui-styled,.UI-styled').UIForms();
};

Site.priceFormat = function (price) {
    //price = price.replace('.', ',');
    return price>=0 ? price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',') : price;
};

/**
 * loader opdalany na buttonach
 * jeśli parametr button będzie pusty wyłącza wszystkie uruchomione loader na buttonach
 * @param button_obj (jquery object)
 */
Site.buttonLoader = function (button_obj) {
    if (button_obj && button_obj.get(0)) {
        if (!button_obj.data('default-html')) {
            button_obj.data('default-html', button_obj.html());
        }
        button_obj.css({width: button_obj.outerWidth()});
        button_obj.html('');
        button_obj.addClass('loading');
    } else {
        $('[class*="ui-button"].loading').each(function () {
            $(this).css('width', '');
            if (!$(this).attr('style')) {
                $(this).removeAttr('style');
            }
            $(this).removeClass('loading');
            $(this).html($(this).data('default-html'));
        });
    }
};

Site.rwd = function () {
    var grid_breakpoints = {};
    grid_breakpoints[this.grid.xs] = 'xs';
    grid_breakpoints[this.grid.sm] = 'sm';
    grid_breakpoints[this.grid.md] = 'md';
    grid_breakpoints[this.grid.lg] = 'lg';
    grid_breakpoints[this.grid.xl] = 'xl';

    var window_size = $(window).width();

    var data = {
        width: window_size
    };

    if (window_size >= 1236) {
        data.breakpoint = 'max';
        data.breakpoint_start = 1236;
    } else {

        var breakpoint = 0;

        for (var index in grid_breakpoints) {
            if (window_size < index && window_size >= breakpoint) {
                var value = grid_breakpoints[index];
                data.breakpoint = value;
                data.breakpoint_start = breakpoint;
                data.breakpoint_end = index - 1;
                break;
            }
            breakpoint = index;
        }

    }
    Site.rwd_breakpoint = data.breakpoint;
    $('body, .ui-grid-breakpoint').attr('class',
        function (i, c) {
            return c ? c.replace(/(^|\s)breakpoint-\S+/g, ''): '';
        });

    $('body, .ui-grid-breakpoint').addClass('breakpoint-' + data.breakpoint).data('breakpoint', data.breakpoint);

    return data;
};

Site.rwdDevice = function () {
    function iOS() {

        var iDevices = [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ];

        if (!!navigator.platform) {
            while (iDevices.length) {
                if (navigator.platform === iDevices.pop()) {
                    return true;
                }
            }
        }

        return false;
    }
};

Site.isTouchDevice = function () {
    return 'ontouchstart' in window || navigator.maxTouchPoints;
};

Site.tooltipInit = function () {
    $('.tooltip').each(function () {
        if (Site.isTouchDevice()) {
            $(this).on('click', function (e) {
                e.stopPropagation();
                e.preventDefault();

                var x = $(this).offset().left;
                var y = $(this).offset().top;
                var w = $(this).outerWidth(true);
                var h = $(this).outerHeight(true);
                if ($(this).attr('title')) {
                    $(this).data('title', $(this).attr('title'));
                    $(this).removeAttr('title');
                }
                Site.tooltipOpen($(this).data('title'), x, y, w, h);
            });
        } else {
            $(this).on('mouseover', function () {
                var x = $(this).offset().left;
                var y = $(this).offset().top;
                var w = $(this).outerWidth(true);
                var h = $(this).outerHeight(true);
                if ($(this).attr('title')) {
                    $(this).data('title', $(this).attr('title'));
                    $(this).removeAttr('title');
                }
                Site.tooltipOpen($(this).data('title'), x, y, w, h);
            });
            $(this).on('mouseout', function () {
                Site.tooltipClose();
            });
        }


    });
};

/**
 *
 * @param string
 * @param x - offset.left buttona uruchamiającego tooltip
 * @param y - offset.top buttona uruchamiającego tooltip
 * @param trigger_w - szerokość buttona uruchamiającego tooltip
 * @param trigger_h - wysokość buttona uruchamiającego tooltip
 */

Site.tooltipStickyOpen = function (string, x, y, delay) {
    Site.tooltipOpen(string, x, y, 0, 0, true, delay);
};
Site.tooltipOpen = function (string, x, y, trigger_w, trigger_h, sticky, autohide_delay) {

    Site.tooltipClose();

    var offset_x = trigger_w ? trigger_w : 20;
    var offset_y = trigger_h ? trigger_h : 20;

    var layer = $('<div class="ui-tooltip"><span class="ui-tooltip-close"><i class="icon falsemark light"></i></span><div class="ui-tooltip-content"></div></div>');
    $('.ui-tooltip-content', layer).html(string);


    if (!Site.isTouchDevice() && !sticky) {
        $('.ui-tooltip-close', layer).remove();
    }
    if (sticky) {
        layer.addClass('sticky');
    }
    layer.hide();
    $('body').append(layer);

    var window_width = $(window).width();
    var window_height = $(window).height();

    var x = x ? x : 0;
    var y = y ? y : 0;
    if (x == 'left') {
        x = 20;
    }
    if (y == 'top') {
        y = window_height - 20;
    }
    layer.css({
        left: 0,
        top: 0,
    });
    layer.fadeIn(150, function () {
        if (autohide_delay) {
            setTimeout(function () {
                layer.stop().clearQueue().fadeOut(350, function () {
                    $(this).remove();
                })
            }, autohide_delay);
        }
    });


    var layer_w = layer.outerWidth();
    var layer_h = layer.outerHeight();

    //var window_viewport_x = $(window).scrollLeft() + window_width;
    var window_viewport_y = $(window).scrollTop() + window_height;

    var left = (x + offset_x + layer_w) <= window_width ? (x + offset_x) : (x - offset_x - layer_w);
    var top = (y + offset_y + layer_h) <= window_viewport_y ? (y + offset_y + 10) : (y - offset_y - layer_h);

    if(left < $(window).scrollLeft()) {
        left = $(window).scrollLeft();
    }

    layer.css({
        left: left,
        top: top
    });

    $('.ui-tooltip-close').on('click', function (e) {
        Site.tooltipClose();
    });

    if (!sticky) {
        $(document).on('click.tooltip', function () {
            Site.tooltipClose();
        });
    }
}
;
Site.tooltipClose = function () {
    $(document).off('click.tooltip');
    $('.ui-tooltip').stop().clearQueue().fadeOut(150, function () {
        $(this).remove();
    });
};

Site.showCookiesPolicy = function () {
    if (Cookies.get('lp_cinfo')) return;

    if (!$('#cookies-policy').length) {

        var html = [];
        html[0] = ' <div class="container">';
        html[1] = '     <div class="content">';
        html[2] = '         <span>';
        html[3] = '             '+cookies_policy_texts['top']+' <a class="link" href="informacje-o-cookies">'+cookies_policy_texts['link']+'</a>.';
        html[4] = '         </span> ';
        html[5] = '         <span>';
        html[6] = '             '+cookies_policy_texts['bottom'];
        html[7] = '         </span>';
        html[8] = '     </div>';
        html[9] = '     <a class="ui-button-short" onclick="Site.closeCookiesPolicy();return false;">';
        html[10]= '         <svg><rect x="5" y="5" rx="17" fill="none" width="109" height="34"></rect></svg>';
        html[11]= '         <span>'+cookies_policy_texts['button']+'</span>;';
        html[12]= '     </a>';
        html[13]= '</div>';

        var box = $('<div id="cookies-policy"></div>');
        box.html(html.join('')).prependTo('body');
        $('body').addClass('cookies-policy');
    }
};

Site.closeCookiesPolicy = function () {
    var dt = new Date();
    dt.setDate(dt.getDate() + 365);
    Cookies.set('lp_cinfo', '1', {expires: dt, domain: base_cookie_domain});
    $('#cookies-policy').remove();
    $('body').removeClass('cookies-policy');
};

Site.isMobile = function () {
    if (Site.rwd_breakpoint == 'xs' || Site.rwd_breakpoint == 'sm') {
        return true;
    } else {
        return false;
    }
};

Site.scrollToHash = function () {
    if (window.location.hash) {
        var hashId = window.location.hash;
        if ($(hashId).length) {
            var scrollTo = parseInt($(hashId).offset().top);
            if ($('body').is('.header-sticky')) {
                scrollTo = scrollTo - parseInt($('#header').height());
            }
            
            $('html,body').stop().clearQueue().animate({scrollTop: scrollTo}, 500);
            
            return false;
        }
        
    }
};

Site.scrollToID = function () {
    if ($('.scroll-to-id').length) {
        $('.scroll-to-id').each(function(){
            $(this).on('click',function(){
                var id = $(this).attr('href');
                var scrollTo = parseInt($(id).offset().top);
                if ($('body').is('.header-sticky')) {
                    scrollTo = scrollTo - parseInt($('#header').height());
                }
                
                $('html,body').stop().clearQueue().animate({scrollTop: scrollTo}, 500);

                return false;
            });
        });
    }
};

Site.autocomplete = function(){
    if ($('input.autocomplete').length){
        $('input.autocomplete').each(function(){
            var source = $(this).data('source');
            if (source){
                $(this).autocomplete({
                    source: source,
                    appendTo: $(this).parent()
                });
            }
        });
    }
};

Site.setLanguageActions = function(){
    $wrapper = $('#menu-languages-items');
    $wrapper.find('a')
        .off('click')
        .on('click',function(e){
            e.preventDefault();
            var action = $wrapper.data('set-language-action');
            var requestUri = $wrapper.data('request-uri');
            var controllerName = $wrapper.data('request-controller');
            var actionName = $wrapper.data('request-action');
            var language = $(this).data('language-code');
            $.post(
                action, 
                {
                    'newlanguage'   : language,
                    'controllername': controllerName,
                    'actionname'    : actionName,
                    'requesturi'    : requestUri,
                    'ajax'          : 1
                },
                function (res) {
                    if (res.success == 1 && res.redirect){
                        window.location.href = res.redirect; 
                    } else {
                        console.log('Problem ze zmianą wersji językowej:',res);
                        if (res.error){
                            Site.alert(res.error);
                        }
                    }
                }, 
                'json'
            );
            
            return false;
        })
    ;
}

Site.setCurrencyActions = function(){
    $('#menu-currencies-items a')
        .off('click')
        .on('click',function(e){
            e.preventDefault();
            var action = $('#menu-currencies-items').data('set-currency-action');
            var currency = $(this).data('currency-code');
            $.post(
                action, 
                {
                    'currency'  : currency,
                    'ajax'      : 1
                },
                function (res) {
                    if (res.success == 1){
                        window.location.reload(); 
                    } else {
                        console.log('Problem ze zmianą waluty:',res);
                        if (res.error){
                            Site.alert(res.error);
                        }
                    }
                }, 
                'json'
            );
            
            return false;
        })
    ;
};

/**
 * uruchamia obsluge newslettera
 */
Site.newsletter = function () {
    function validEmail(email) {
        var regValidEmail = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return regValidEmail.test(email);
    }

    $('form#newsletter').on('submit', function (e) {
        Site.overlay.close();
        e.preventDefault();

        var form = $(this);
        var email = $('input[name=email]', form).val();

        var alertEmptyEmail = $(this).data('alert-empty-email');
        var alertBadEmail = $(this).data('alert-bad-email');
        var agreeMessage = $(this).data('agree-message');
        
        if (email === '') {
            Site.alert(alertEmptyEmail);
        } else if (!validEmail(email)) {
            Site.alert(alertBadEmail);
        } else {
            Site.confirm(agreeMessage, function() {
                $.post(form.attr('action'), form.serialize() + '&ajax=1', function (data) {
                    Site.buttonLoader();
                    Site.overlay.close();

                    if (data.status === 'error') {
                        Site.alert(data.message);
                    } else {
                        $('.newsletter-content', form).html(data.message);
                    }
                }, 'json');

            }, null, {
                save: 'Tak',
                cancel: 'Nie'
            });


        }
        return false;

    });
};

Site.headerSearch = function(){
    $('#header-search-form').find('input').each(function(){
        var source = $(this).data('source');
        if (source){
            $(this).autocomplete({
                source: source,
                appendTo: $(this).parent(),
                select: function( event, ui ) {
                    window.location.href=ui.item.url;
                    return false;
                }
            })
            .data("ui-autocomplete")._renderItem = function( ul, item ) {
                $div = $( "<div>" )
                    .addClass('ui-menu-item-wrapper')
                ;
                if (item.image){
                    $div.append(
                        $('<img>')
                        .attr({
                            'src' : item.image
                        })
                    );
                }
                $div.append(item.label);
                
                $li = $( "<li>" ).append($div);
                
                return $li.appendTo(ul);
            };
        }
    });
};

Site.refreshHpTechnologiesSliderDots = function(currentIndex){
    if (!(currentIndex > 0)){
        currentIndex = 1;
    }
    
    var index = parseInt($('.carousel3d-plus-item').eq(currentIndex).data('slide-number'));
    var count = $('#technologies').data('count');
    var division = count > 0 ? Math.round(count/3) : 1;
    
    $('.carousel3d-plus-dots .carousel3d-plus-dot').removeClass('active');
    if (index < division){
        $('.carousel3d-plus-dots .carousel3d-plus-dot').eq(0).addClass('active');
    } else if (index > (count-division)){
        $('.carousel3d-plus-dots .carousel3d-plus-dot').eq(2).addClass('active');
    } else {
        $('.carousel3d-plus-dots .carousel3d-plus-dot').eq(1).addClass('active');
    }
};

Site.google_recaptcha_api_loading = false;
Site.google_recaptcha_api_loaded = false;
Site.google_recaptcha_site_key = '';
Site.gReCaptcha = function() {
    if(!this.google_recaptcha_api_loaded && !this.google_recaptcha_api_loading) {
        this.google_recaptcha_api_loading = true;
        var grecaptcha_field = $('.g-recaptcha');
        if(grecaptcha_field.get(0) && grecaptcha_field.data('sitekey')) {
            Site.google_recaptcha_site_key = grecaptcha_field.data('sitekey');
            var grecaptcha_form = grecaptcha_field.closest('form');
            grecaptcha_form.addClass('loading');
            $.getScript('https://www.google.com/recaptcha/api.js?render=' + Site.google_recaptcha_site_key, function (data, textStatus, jqxhr) {
                $('body').addClass('recaptcha-active');
                grecaptcha_form.removeClass('loading');
                Site.google_recaptcha_api_loaded = true;
                Site.google_recaptcha_api_loading = false;
            });
        }
    }
};

Site.priceFormat = function (price) {
    return price >= 0 ? price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1 ').replace('.', ',') : price;
};

Site.initUnitegallery = function (){
    
    $('.unitegallery').unitegallery({
					
        //theme options:

        theme_enable_preloader: false,                  //enable preloader circle
        theme_preloading_height: 200,                   //the height of the preloading div, it show before the gallery
        theme_preloader_vertpos: 100,                   //the vertical position of the preloader
        theme_gallery_padding: 0,                       //the horizontal padding of the gallery from the sides
        theme_appearance_order: "normal",               //normal, shuffle, keep - the appearance order of the tiles. The keep is "keep order"
        theme_auto_open:null,                           //auto open lightbox at start - if some number gived, like 0

        //gallery options:

        gallery_theme: "tiles",                         //choose gallery theme (if more then one themes includes)
        gallery_width:"100%",                           //gallery width
        gallery_min_width: 150,                         //gallery minimal width when resizing
        gallery_background_color: "",                   //set custom background color. If not set it will be taken from css.

        //tiles options:

        tiles_col_width: 400,                           //column width - exact or base according the settings
        tiles_align:"center",                           //align of the tiles in the space
        tiles_exact_width: false,                       //exact width of column - disables the min and max columns
        tiles_space_between_cols: 3,                    //space between images
        tiles_space_between_cols_mobile: 3,             //space between cols for mobile type
        tiles_include_padding: true,                    //include padding at the sides of the columns, equal to current space between cols
        tiles_min_columns: 2,                           //min columns
        tiles_max_columns: 4,                           //max columns (0 for unlimited)

        tiles_set_initial_height: true,                 //columns type related only
        tiles_enable_transition: true,                  //enable transition when screen width change

        //tile design options:

        tile_enable_border:false,                       //enable border of the tile
        tile_border_width:3,                            //tile border width
        tile_border_color:"#333333",                    //tile border color
        tile_border_radius:0,                           //tile border radius (applied to border only, not to outline)

        tile_enable_outline: false,                     //enable outline of the tile (works only together with the border)
        tile_outline_color: "#333333",                  //tile outline color

        tile_enable_shadow:false,                       //enable shadow of the tile
        tile_shadow_h:1,                                //position of horizontal shadow
        tile_shadow_v:1,                                //position of vertical shadow
        tile_shadow_blur:3,                             //shadow blur
        tile_shadow_spread:2,                           //shadow spread
        tile_shadow_color:"#333333",                    //shadow color

        tile_enable_action:true,                        //enable tile action on click like lightbox
        tile_as_link: false,                            //act the tile as link, no lightbox will appear
        tile_link_newpage: true,                        //open the tile link in new page

        tile_enable_overlay: true,                      //enable tile color overlay (on mouseover)
        tile_overlay_opacity: 0.4,                      //tile overlay opacity
        tile_overlay_color: "#33333",                  //tile overlay color

        tile_enable_icons: true,                        //enable icons in mouseover mode
        tile_show_link_icon: false,                     //show link icon (if the tile has a link). In case of tile_as_link this option not enabled
        tile_space_between_icons: 26,                   //initial space between icons, (on small tiles it may change)

        tile_enable_image_effect:false,                 //enable tile image effect
        tile_image_effect_type: "bw",                   //bw, blur, sepia - tile effect type
        tile_image_effect_reverse: false,               //reverce the image, set only on mouseover state

        //tile text panel options:

        tile_enable_textpanel: false,                   //enable textpanel
        tile_textpanel_source: "title",                 //title,desc,desc_title. source of the textpanel. desc_title - if description empty, put title
        tile_textpanel_always_on: false,                //textpanel always visible
        tile_textpanel_appear_type: "slide",            //slide, fade - appear type of the textpanel on mouseover
        tile_textpanel_position:"inside_bottom",        //inside_bottom, inside_top, inside_center, top, bottom the position of the textpanel
        tile_textpanel_offset:0,                        //vertical offset of the textpanel

        tile_textpanel_padding_top:8,                   //textpanel padding top 
        tile_textpanel_padding_bottom:8,                //textpanel padding bottom
        tile_textpanel_padding_right: 11,               //cut some space for text from right
        tile_textpanel_padding_left: 11,                //cut some space for text from left
        tile_textpanel_bg_opacity: 0.4,                 //textpanel background opacity
        tile_textpanel_bg_color:"#000000",              //textpanel background color
        tile_textpanel_bg_css:{},                       //textpanel background css

        tile_textpanel_title_color:null,                //textpanel title color. if null - take from css
        tile_textpanel_title_font_family:null,          //textpanel title font family. if null - take from css
        tile_textpanel_title_text_align:null,           //textpanel title text align. if null - take from css
        tile_textpanel_title_font_size:null,            //textpanel title font size. if null - take from css
        tile_textpanel_title_bold:null,                 //textpanel title bold. if null - take from css
        tile_textpanel_css_title:{},                    //textpanel additional css of the title

        tile_textpanel_desc_color:null,                 //textpanel description font family. if null - take from css
        tile_textpanel_desc_font_family:null,           //textpanel description font family. if null - take from css
        tile_textpanel_desc_text_align:null,            //textpanel description text align. if null - take from css
        tile_textpanel_desc_font_size:null,             //textpanel description font size. if null - take from css
        tile_textpanel_desc_bold:null,                  //textpanel description bold. if null - take from css
        tile_textpanel_css_description:{},              //textpanel additional css of the description

        //lightbox options:

        lightbox_type: "wide",                          //compact / wide - lightbox type

        lightbox_hide_arrows_onvideoplay: true, 	//hide the arrows when video start playing and show when stop
        lightbox_arrows_position: "sides",              //sides, inside: position of the arrows, used on compact type			
        lightbox_arrows_offset: 10,                     //The horizontal offset of the arrows
        lightbox_arrows_inside_offset: 10,              //The offset from the image border if the arrows placed inside
        lightbox_arrows_inside_alwayson: false,         //Show the arrows on mouseover, or always on.

        lightbox_overlay_color:null,                    //the color of the overlay. if null - will take from css
        lightbox_overlay_opacity:1,                     //the opacity of the overlay. for compact type - 0.6
        lightbox_top_panel_opacity: null,               //the opacity of the top panel

        lightbox_close_on_emptyspace:true,              //close the lightbox on empty space

        lightbox_show_numbers: true,                    //show numbers on the right side
        lightbox_numbers_size: null,                    //the size of the numbers string
        lightbox_numbers_color: null,                   //the color of the numbers
        lightbox_numbers_padding_top:null,              //the top padding of the numbers (used in compact mode)
        lightbox_numbers_padding_right:null,            //the right padding of the numbers (used in compact mode)

        lightbox_slider_image_border: true,             //enable border around the image (for compact type only)
        lightbox_slider_image_border_width: 10,         //image border width
        lightbox_slider_image_border_color: "#ffffff",  //image border color
        lightbox_slider_image_border_radius: 0,         //image border radius

        lightbox_slider_image_shadow: true,             //enable border shadow the image

        lightbox_slider_control_swipe:true,             //true,false - enable swiping control
        lightbox_slider_control_zoom:true,              //true, false - enable zooming control

        //lightbox text panel:

        lightbox_show_textpanel: true,                  //show the text panel
        lightbox_textpanel_width: 550,                  //the width of the text panel. wide type only

        lightbox_textpanel_enable_title: true,          //enable the title text
        lightbox_textpanel_enable_description: false,	//enable the description text

        lightbox_textpanel_padding_top:5,		//textpanel padding top 
        lightbox_textpanel_padding_bottom:5,		//textpanel padding bottom
        lightbox_textpanel_padding_right: 11,		//cut some space for text from right
        lightbox_textpanel_padding_left: 11,		//cut some space for text from left

        lightbox_textpanel_title_color:null,		//textpanel title color. if null - take from css
        lightbox_textpanel_title_font_family:null,	//textpanel title font family. if null - take from css
        lightbox_textpanel_title_text_align:null,	//textpanel title text align. if null - take from css
        lightbox_textpanel_title_font_size:null,	//textpanel title font size. if null - take from css
        lightbox_textpanel_title_bold:null,		//textpanel title bold. if null - take from css
        lightbox_textpanel_css_title:{},		//textpanel additional css of the title

        lightbox_textpanel_desc_color:null,		//textpanel description font family. if null - take from css
        lightbox_textpanel_desc_font_family:null,	//textpanel description font family. if null - take from css
        lightbox_textpanel_desc_text_align:null,	//textpanel description text align. if null - take from css
        lightbox_textpanel_desc_font_size:null,		//textpanel description font size. if null - take from css
        lightbox_textpanel_desc_bold:null,		//textpanel description bold. if null - take from css
        lightbox_textpanel_css_description:{},		//textpanel additional css of the description
    }); 
    
};
