$.fn.UIForms = function (method) {
    var id_counter = 0;

    if (method) {

        switch (method) {
            case 'check':
                if (
                    $(this).attr('type')
                    && (
                        $(this).attr('type') == 'radio'
                        || $(this).attr('type') == 'checkbox'
                    )
                ) {
                    $(this).prop("checked", true);//.trigger('change');
                }
                break;
            case 'uncheck':
                if (
                    $(this).attr('type')
                    && (
                        $(this).attr('type') == 'radio'
                        || $(this).attr('type') == 'checkbox'
                    )
                ) {
                    $(this).prop("checked", false);//.trigger('change');
                }
                break;
            case 'enable':
                $(this).prop('disabled', false);
                break;
            case 'disable':
                $(this).prop('disabled', true);
                break;
            case 'update':
                $('.ui-styled').each(function () {
                    //$('input[type=radio], input[type=checkbox]', $(this)).trigger('change');
                });
                break;
        }

    } else {

        $(this).each(function () {
            id_counter++;
            var container = $(this);
            if (!container.is('.ui-styled')) {
                container.addClass('ui-styled');
            }

            $('select', container).each(function () {
                var input = $(this);
                if (input.is('select') && !input.is('.do-not-auto-styled')) {
                    input.select2({
                        "language"                  : "pl",
                        "dropdownParent"            : input.parent(),
                        "minimumResultsForSearch"   : isTouchDevice() ? Infinity : 0
                    });
                }
            });

            $('input[type=radio]', container).each(function () {
                var input = $(this);
                var parent = $(this).parent();

                var input_id = input.attr('id');
                if (!input_id) {
                    var input_id = 'ui-form-' + id_counter;
                    input.attr('id', input_id);
                }

                var label = $('label', input.parent());
                if (!label.get(0)) {
                    var label = $('<label></label>');
                    input.before(label);
                }

                if (!label.attr('for')) {
                    label.attr('for', input_id);
                }


                if (!parent.hasClass('ui-radio')) {
                    parent.addClass('ui-radio');
                }
                var inputName = input.attr('name');
                input.off('change.uiforms').on('change.uiforms', function () {
                    $('input[type=radio][name="' + inputName + '"]').each(function () {
                        if ($(this).is(':checked')) {
                            $(this).parent().addClass('checked');
                        } else {
                            $(this).parent().removeClass('checked');
                        }
                    });
                    //console.log('radio.change');
                });

                input.is(':checked') ? parent.addClass('checked') : parent.removeClass('checked');
                input.is(':disabled') ? parent.addClass('disabled') : parent.removeClass('disabled');

                if (!input.data('styled')) {
                    input.data('styled', true);
                    label.prepend('<span class="checker"></span>');
                }

            });

            $('input[type=checkbox]:not("data")', container).each(function () {
                var input = $(this);
                var parent = $(this).parent();
                var input_id = input.attr('id');
                if (!input_id) {
                    var input_id = 'ui-form-' + id_counter;
                    input.attr('id', input_id);
                }

                var label = $('label', input.parent());
                if (!label.get(0)) {
                    var label = $('<label></label>');
                    input.before(label);
                }

                if (!label.attr('for')) {
                    label.attr('for', input_id);
                }

                if (!parent.is('.ui-checkbox')) {
                    parent.addClass('ui-checkbox');
                }
                input.on('click', function (e) {
                    if ($(this).is(':checked')) {
                        parent.addClass('checked');
                    } else {
                        parent.removeClass('checked');
                    }

                    if ($(this).is(':disabled')) {
                        parent.addClass('disabled');
                    } else {
                        parent.removeClass('disabled');
                    }
                });
                input.on('uncheck', function (e) {
                    $(this).prop('checked', false);
                    parent.removeClass('checked');
                });

                input.off('change.uiforms').on('change.uiforms', function() {

                });


                input.is(':checked') ? parent.addClass('checked') : parent.removeClass('checked');
                input.is(':disabled') ? parent.addClass('disabled') : parent.removeClass('disabled');

                if (!input.data('styled')) {
                    input.data('styled', true);
                    label.prepend('<span class="checker"></span>');
                }
                //console.log('checkbox.change');

            });

            //spinner zwiększ/zmniejsz wartość przy inpucie
            $('.ui-spinner').each(function () {
                var input = $(this);
                if (!input.data('spinner-activated')) {

                    var wrapper = input.wrap('<span class="ui-spinner-wrapper"></span>');

                    var decrement = $('<a class="ui-spinner-decrement" href="#"></a>');
                    var increment = $('<a class="ui-spinner-increment" href="#"></a>');

                    decrement.off('click').on('click', function (e) {
                        e.preventDefault();
                        var value = Math.abs(input.val()) - 1;
                        if (value <= 1) {
                            value = 1;
                            $(this).addClass('disabled');
                        }
                        $('.ui-spinner', $(this).parent()).val(value);
                        input.trigger('change');
                    });
                    increment.off('click').on('click', function (e) {
                        e.preventDefault();
                        var value = Math.abs(input.val()) + 1;
                        $('.ui-spinner', $(this).parent()).val(value);
                        $('.ui-spinner-decrement', $(this).parent()).removeClass('disabled');
                        input.trigger('change');
                    });

                    input.before(decrement);
                    input.after(increment);

                    var value = Math.abs(input.val());
                    if (value <= 1) {
                        decrement.addClass('disabled');
                    }
                    input.data('spinner-activated', true);
                }
            });
        });
    }
};
