var Form = function(formId, settings) {
    var config = {
        styling             : true,
        responseContainer   : null ,
        onSubmit            : function(){} ,
        onCorrectSubmited   : function(){} ,
        onInit              : function(){}
    };
    if (settings) $.extend(true, config, settings);
    
    var $form = $("#"+formId);

    var reset = function(){
        if (config.responseContainer != null && config.responseContainer.html() != '') {
            config.responseContainer.empty().hide();
            $form.trigger('reset').show();
            
             if (config.responseContainer != null) {
                config.responseContainer.removeClass('active').html('').hide();
            }
        }
    };
    
    var init = function(){
        $form.off('submit').on('submit', function(){
            $form.find('.form-info-box').remove();
            var button_submit = $('button[type=submit].active ').length ? $('button[type=submit].active', $form) : $('button[type=submit]', $form);

            config.onInit();
            
            Site.buttonLoader(button_submit);
            var siteButtonLoaderOff = function() {
                if(Site && Site.buttonLoader) {
                    Site.buttonLoader();
                }
            };

            if(Site.google_recaptcha_site_key) {
                $form.addClass('loading');
                grecaptcha.ready(function () {
                    grecaptcha.execute(Site.google_recaptcha_site_key, {action: 'social'}).then(function (token) {
                        send_form_data('recaptcha=' + token);
                    });
                });
            } else {
                send_form_data();
            }
            
            return false;


            //wyslij posta ajaxem
            function send_form_data (additional_data_str) {
                $.post(
                    $form.attr('action'),
                    $form.serialize()+'&ajax=1' + (additional_data_str ? '&' + additional_data_str : ''),
                    function(result){

                        $form.removeClass('loading');

                        //zwrocil tablice z bledami
                        if (result.errors){
                            //ukryj inne komunkaty
                            $('.infoBox').hide();

                            //najpierw wyczysc wszystkie pola z errorow
                            $form.find('.form-row')
                                .removeClass('form-row-error')
                                .find('.form-error').html('');

                            //dodaj bledy
                            setErrors(result.errors);
                            Site.buttonLoader();
                            //zwrocono redirect
                        } else if (result.redirect){
                            config.onCorrectSubmited();

                            (window !== window.top)
                                ? parent.window.location.href = result.redirect
                                : window.location.href = result.redirect;
                            //zwrocono komunikat nad formularzem
                        } else if (config.responseContainer != null) {
                            config.onCorrectSubmited();

                            $('html, body').animate({
                                scrollTop: $form.offset().top-200
                            }, 600);
                            $form.hide();
                            config.responseContainer.addClass('active').html(result.formmessage).show();
                            siteButtonLoaderOff();
                            //zwrocono komunikat nad formularzem
                        } else if (result.message) {
                            config.onCorrectSubmited();

                            if (typeof Site.alert === "function") {
                                Site.alert(result.message);
                            } else {
                                //ukryj inne komunkaty
                                $('.infoBox').hide();

                                //ukryj ponownie to co jest domyslnie ukryte (ma klase none)
                                $form.find('.none').hide();
                                var messageClass = (result.success === 0) ? 'form-error-box' : 'form-info-box';
                                //usun poprzedni komunikat jesli jest
                                $('.'+messageClass).remove();
                                $form.prepend('<div class="'+messageClass+'"><div class="inner">'+result.message+'</div></div>');

                                $('html, body').animate({
                                    scrollTop: $form.offset().top-200
                                }, 1000);
                            }
                            siteButtonLoaderOff();
                            //zwrocono komunikat zamiast formularza
                        } else if (result.formmessage) {
                            config.onCorrectSubmited();

                            if (typeof Site.alert === "function") {
                                Site.alert(result.message);
                            } else {
                                //ukryj inne komunkaty
                                $('.infoBox').hide();

                                //usun poprzedni komunikat jesli jest
                                $('.form-info-box').remove();
                                $form.html('<div class="form-info-box"><div class="inner">'+result.formmessage+'</div></div');

                                $('html, body').animate({
                                    scrollTop: $form.offset().top-200
                                }, 1000);
                            }
                            siteButtonLoaderOff();
                            //zwrocono html zamiast formularza
                        } else if (result.html) {
                            config.onCorrectSubmited();

                            //podmien kontent
                            $('.subpage > .container').html(result.html);
                            siteButtonLoaderOff();
                        }

                        //odswiez captche jesli jest
                        if ($form.find('.form-field').is('.form-captcha')){
                            $form.find('.form-captcha-img img').remove();
                            $form.find('.form-captcha-img').prepend('<img style="visibility: hidden;" src="/captcha.php?ts='+new Date().getTime()+'"/>');
                            $form.find('.form-captcha-img img').load(function() {
                                $form.find('.form-captcha-img img').css({'visibility':'visible'});
                            });
                            siteButtonLoaderOff();
                        }
                        $form.find('#formoverlay').remove();

                        config.onSubmit(result);
                    },
                    'json'
                );
            }

    
            return false;
        });

        $form.find('.form-row').each(function(){
            var id = $(this).prop('id').replace('form-row-','');
            
            if (($(this).hasClass('form-row-valid') || $(this).hasClass('form-row-ajaxvalid')) && !$(this).hasClass('disable-ajaxvalid')){
                var $parent = $(this);
                var $field = $parent.hasClass('form-row-array') ? $parent.find('input') : $parent.find('#'+id);
                if (!($parent.find('select').length > 0)){
                    $field
                        .off('focusout').on('focusout', function(){ 
                            var resValid = true;
                            if ($parent.is('.form-row-valid')) {
                                resValid = FormValid.checkField($(this));
                            }
                            if (resValid && $parent.is('.form-row-ajaxvalid')){
                                //window.setTimeout(function() { AjaxFormValid.checkField($(this)); }, 500);
                            } 
                        })
                        .off('focusin').on('focusin', function(){ 
                            $parent.removeClass('form-row-error').find('.form-error').html('');
                        })
                    ;
                }
            } 
        });
        
        $form.find('textarea[maxlength]').off('keyup').on('keyup', function(){  
            //get the limit from maxlength attribute  
            var limit = parseInt($(this).attr('maxlength'));  
            //get the current text inside the textarea  
            var text = $(this).val();  
            //count the number of characters in the text  
            var chars = text.length;  

            //check if there are more characters then allowed  
            if(chars > limit){  
                //and if there are use substr to get the text before the limit  
                var new_text = text.substr(0, limit);  

                //and change the current text with the new text  
                $(this).val(new_text);  
            }  

            var tooltip = 'pozostało ' + (limit - chars) + ' znaków<br>(maksymalnie ' + limit + ' znaków)';
            $(this).parents('.form-row').find(".form-tooltip").html(tooltip);
        });  
        
        if (config.styling) {
            $('.ui-styled').UIForms();
        }
        
        if (jQuery().tipTip && $form.find('.form-tooltip-icon').length > 0) {
            $form.find('.form-tooltip-icon').tipTip({
                maxWidth: "700px",
                edgeOffset: 10, 
                defaultPosition: "right",
                delay: 0,
                fadeIn: 0,
                fadeOut: 0
            });
        }
        
        $form.focus();
    };
    
    var setErrors = function(errors){
        for(var key in errors){
            $form
                .find('#form-row-'+key)
                    .addClass('form-row-error')
                    .find('.form-error')
                        .html(errors[key])
            ;
        }
    };
    
    reset();
    init();
};

var FormValid = {
    id: '',
    value: '',
    errorMessages: {
        'default'     : 'Błąd pola',
        'empty'       : 'Pole nie może być puste',
        'confirm'     : 'Pole musi mieć taką sama wartość co pole %s',
        'minlength'   : 'Minimalnie %d znaków',
        'maxlength'   : 'Maksymalnie %d znaków',
        'nip'         : 'Niepoprawny NIP',
        'phone'       : 'Niepoprawny nr telefonu',
        'pesel'       : 'Niepoprawny Pesel',
        'regon'       : 'Niepoprawny Regon',
        'not'         : 'Pole nie może mieć wartości %s',
        'checked'     : 'Pole musi być zaznaczone',
        'email'       : 'Niepoprawny email',
        'postcode'    : 'Niepoprawny kod pocztowy',
        'numeric'     : 'Pole musi być liczbą'        
    },
    checkField: function(field){
        FormValid.id = field.attr('id');
        FormValid.value = field.val();
        
        var validResult = true; 

        var valid = field.parents('.form-row').attr('data-valid');
        var errorMessage = '';
        if (valid){
            var validType = valid.split(',');
            for(var key in validType){
                if (validResult){
                    var type = validType[key].split(':');
                    switch (type[0]){
                        case 'empty':
                          errorMessage = FormValid.validateEmpty();
                          break;
                        case 'email':
                          errorMessage = FormValid.validateEmail();
                          break;
                        case 'maxlength':
                          errorMessage = FormValid.validateMaxlength(type[1]);
                          break;
                        case 'minlength':
                          errorMessage = FormValid.validateMinlength(type[1]);
                          break;
                        case 'not':
                          errorMessage = FormValid.validateNot(type[1]);
                          break;
                        case 'notzero':
                          errorMessage = FormValid.validateNotZero();
                          break;
                        case 'numeric':
                          errorMessage = FormValid.validateNumeric();
                          break;
                        case 'checked':
                          errorMessage = FormValid.validateChecked();
                          break;
                        case 'postcode':
                          errorMessage = FormValid.validatePostcode();
                          break;
                        case 'nip':
                          errorMessage = FormValid.validateNip();
                          break;
                        case 'pesel':
                          errorMessage = FormValid.validatePesel();
                          break;
                        case 'regon':
                          errorMessage = FormValid.validateRegon();
                          break;
                        case 'phone':
                          errorMessage = FormValid.validatePhone();
                          break;
                        default:
                          errorMessage = true;
                    }
                    
                    if (errorMessage !== true) {
                        validResult = false;
                        $('#'+FormValid.id)
                            .parents('.form-row')
                                .removeClass('form-row-ok')
                                .addClass('form-row-error')
                                .find('.form-error')
                                    .html(errorMessage)
                        ; 
                        break;
                    } else {
                        $('#'+FormValid.id)
                            .parents('.form-row')
                                .addClass('form-row-ok')
                                .removeClass('form-row-error')
                        ;
                    }
                } else break;  
            }
        }
  
        return validResult;
        
    },
    validateEmpty: function(){
        return ((!FormValid.value || FormValid.value === '') && FormValid.value !== 0) 
            ? FormValid.generateErrorMessage('empty') 
            : true;
    },
    validateEmail: function(){
        var reg = /^([A-Za-z0-9_\-\.\+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        return (!(!FormValid.value || FormValid.value === '' || reg.test(FormValid.value)))
            ? FormValid.generateErrorMessage('email')
            : true;
    },
    validateMaxlength: function(length){
        return (FormValid.value && FormValid.value !== '' && FormValid.value.length > length)
            ? FormValid.generateErrorMessage('maxlength',length)
            : true;
    },
    validateMinlength: function(length){
        return (FormValid.value && FormValid.value !== '' && FormValid.value.length < length)
            ? FormValid.generateErrorMessage('minlength',length)
            : true;
    },
    validateNot: function(not){
        return (FormValid.value === not)
            ? FormValid.generateErrorMessage('not',not)
            : true;
    },
    validateNotZero: function(){
        return (FormValid.value === 0)
            ? FormValid.generateErrorMessage('not',0)
            : true;
    },
    validateNumeric: function(){
        var val = FormValid.value.replace(/,/gi, '.');
        return (FormValid.value && FormValid.value !== '' && parseFloat(val) != val) 
            ? FormValid.generateErrorMessage('numeric')
            : true;
    },  
    validateChecked: function(){
        return (!$('#'+FormValid.id).is(':checked'))
            ? FormValid.generateErrorMessage('checked')
            : true;
    },
    validatePostcode: function(){
    /* dla polski
        var postcodeRegEx = /^[0-9]{2}\-[0-9]{3}$/;
        return (!postcodeRegEx.test(FormValid.value))
            ? FormValid.generateErrorMessage('postcode')
            : true;
    */
        return (FormValid.value && FormValid.value !== '' && FormValid.value.length > 10)
            ? FormValid.generateErrorMessage('postcode')
            : true;
    }, 
    validateNip: function(){
         return (FormValid.value && FormValid.value !== '' && !FormValid._checkNIP(FormValid.value))
            ? FormValid.generateErrorMessage('nip')
            : true;
    }, 
    validatePesel: function(){
        return (FormValid.value && FormValid.value !== '' && !FormValid._checkPesel(FormValid.value))
            ? FormValid.generateErrorMessage('pesel')
            : true;
    },  
    validateRegon: function(){
        return (FormValid.value && FormValid.value !== '' && !FormValid.checkRegon(FormValid.value))
            ? FormValid.generateErrorMessage('regon')
            : true;
    },
    validatePhone: function(){
        var reg = /^\+{0,1}[0-9\s]{6,20}$/;
        return (!(!FormValid.value || FormValid.value === '' || reg.test(FormValid.value)))
            ? FormValid.generateErrorMessage('phone')
            : true;
    },
    generateErrorMessage: function(code,replaceValue){
        var errorMessage = global_error_messages[code] ? global_error_messages[code] : FormValid.errorMessages[code];
        return errorMessage.replace('%s',String(replaceValue)).replace('%d',parseInt(replaceValue));
    },
    _checkNIP: function(value) {
        var verificator_nip = new Array(6,5,7,2,3,4,5,6,7);
        var nip = value.replace(/[\ \-]/gi, '');
        if (nip.length != 10){
            return false;
        } else  {
            var n = 0;
            for (var i=0; i<9; i++) n += nip[i] * verificator_nip[i];
            n %= 11;
            if (n != nip[9]) {
                return false;
            } 
        }
        return true;
    },
    _checkPesel: function(pesel) {
        if (pesel.length != 11) {
            return false;
        } else {
            var steps = new Array(1, 3, 7, 9, 1, 3, 7, 9, 1, 3);
            var sum_nb = 0;
            for (var x = 0; x < 10; x++) sum_nb += steps[x] * pesel[x];
            sum_m = 10 - sum_nb % 10;
            sum_c = (sum_m == 10) ? 0 : sum_m;
            if (sum_c != pesel[10]) {
                return false;
            }
        }
        return true;
    },
    _checkRegon: function(regon){
        regon+=''; /* do stringa */
        regon=regon.replace(/[^0-9]+/g,''); /* usuwamy zbedne znaki */
        var regonLEN=regon.length;

        var verificator_regon=new Array();
        /* warunek dla 9-ciocyfrowego regonu */
        if(regonLEN==9) {
            verificator_regon=new Array(8,9,2,3,4,5,6,7); 
        /* warunek dla 14-stocyfrowego regonu */
        } else if(regonLEN==14) { 
            verificator_regon=new Array(2,4,8,5,0,9,7,3,6,1,2,4,8);
        } else {
            return false;
        }

        for(var i=0,verificatorLEN=verificator_regon.length,sumaKontrolna=0; i < verificatorLEN; i++){
           sumaKontrolna+=parseInt(regon.charAt(i))*verificator_regon[i];
        }
        
        var cyfraKontrolna=sumaKontrolna%11;

        return (regon==(regon.substring(0,verificatorLEN)+((cyfraKontrolna==10)?'0':cyfraKontrolna)+'')) ? true : false;
     }
};

//@link: http://valums-file-uploader.github.io/file-uploader/
var FileUploaderWrapper = {
    allowedExtensions: ['jpg', 'jpeg', 'png', 'gif', 'pdf', 'doc', 'xls', 'docx'],
    maxFileSize: 0,
    minSizeLimit: 0,
    maxConnections: 3,
    buttonName: (file_uploader_texts['button_name'] ? file_uploader_texts['button_name'] : 'Załącz pliki'),
    multiple: true,
    formId: null,
    init: function(formId,allowedExtensions){
        FileUploaderWrapper.formId = '#'+formId;
        var $form = $(FileUploaderWrapper.formId);
        
        if (allowedExtensions) {
            FileUploaderWrapper.allowedExtensions = allowedExtensions.split(',');
        } else if ($form.attr('data-upload-allowed-extension')){
            FileUploaderWrapper.allowedExtensions = $form.attr('data-upload-allowed-extension').toString().split(',');
        }
        
        var maxFileSize = parseInt($form.attr('data-upload-max-file-size'));
        if (maxFileSize > 0) {
            FileUploaderWrapper.maxFileSize = maxFileSize;
        }
        
        var minSizeLimit = parseInt($form.attr('data-upload-min-size-limit'));
        if (minSizeLimit > 0) {
            FileUploaderWrapper.minSizeLimit = minSizeLimit;
        }
        
        var maxConnections = parseInt($form.attr('data-upload-max-connections'));
        if (maxConnections > 0) {
            FileUploaderWrapper.maxConnections = maxConnections;
            if (FileUploaderWrapper.maxConnections == 1 && $('.upload-files-item').length){
                $(FileUploaderWrapper.formId+' .form-field.form-file').addClass('disable');
                $(FileUploaderWrapper.formId+' .qq-upload-button').hide();
            } else {
                $(FileUploaderWrapper.formId+' .form-field.form-file').removeClass('disable');
                $(FileUploaderWrapper.formId+' .qq-upload-button').show();
            }           
        }
        
        var multiple = $form.attr('data-upload-multiple');
        FileUploaderWrapper.multiple = (multiple === false || multiple === 'false') ? false : true;
        
        var buttonName = $form.attr('data-upload-button-name');
        if (buttonName){
            FileUploaderWrapper.buttonName = buttonName;
        }

        var fileAction = $form.attr('data-upload-action');
        if (fileAction){
            FileUploaderWrapper.fileAction = fileAction;
        } else {
            FileUploaderWrapper.fileAction = $form.attr('action');
        }
        
        if (!($('.upload-files-item').length && FileUploaderWrapper.maxConnections == 1)){
            FileUploaderWrapper._initUploader();
        }
        
        if ($(FileUploaderWrapper.formId+' .upload-files-item').length){
            $(FileUploaderWrapper.formId+' .qq-uploader').addClass('qq-uploader-added');
        } else {
            $(FileUploaderWrapper.formId+' .qq-uploader').removeClass('qq-uploader-added');
        }
        
    },
    deleteFile: function(fileid,fileName){
        $.post(
            FileUploaderWrapper.fileAction,
            { 
                removefile: 1, 
                file: fileName, 
                fileid: fileid 
            },
            function(responseJSON){
                if (responseJSON['success'] === true) {
                    $('#file-'+fileid).remove();
                    if ($('.qq-upload-button').length){
                        $(FileUploaderWrapper.formId+' .qq-upload-button').show();
                    } else {
                        FileUploaderWrapper._initUploader();
                    }
                    $(FileUploaderWrapper.formId+' .form-field.form-file').removeClass('disable');
                    if ($(FileUploaderWrapper.formId+' .upload-files-item').length){
                        $(FileUploaderWrapper.formId+' .qq-uploader').addClass('qq-uploader-added');
                    } else {
                        $(FileUploaderWrapper.formId+' .qq-uploader').removeClass('qq-uploader-added');
                    }
                } else if (responseJSON['error']){
                    $(FileUploaderWrapper.formId+' #file-uploader').parent().parent().addClass('form-row-error').find('.form-error').html(responseJSON['error']);
                } else {
                    console.log(JSON.stringify(responseJSON));
                }
            },
            'json'
        );
    },
    _initUploader: function(){
        var uploader = new qq.FileUploader({
            element: $(FileUploaderWrapper.formId+' #file-uploader')[0],
            action: FileUploaderWrapper.fileAction,
            debug: false,
            // additional data to send, name-value pairs
            params: { allowedExtensions: FileUploaderWrapper.allowedExtensions },
            // validation    
            // ex. ['jpg', 'jpeg', 'png', 'gif'] or []
            allowedExtensions: FileUploaderWrapper.allowedExtensions,        
            // each file size limit in bytes
            // this option isn't supported in all browsers
            sizeLimit: FileUploaderWrapper.maxFileSize, // max size   
            minSizeLimit: FileUploaderWrapper.minSizeLimit, // min size
            messages: {
                // error messages, see qq.FileUploaderBasic for content            
                typeError: (file_uploader_texts['error_type'] ? file_uploader_texts['error_type'] : "Dopuszczalne pliki to {extensions}"),
                sizeError: (file_uploader_texts['error_size'] ? file_uploader_texts['error_size'] : "Plik lub jeden z plików jest za duży. Dopuszczalny rozmiar to {sizeLimit}."),
                minSizeError: (file_uploader_texts['error_min_size'] ? file_uploader_texts['error_min_size'] : "Plik jest za mały. Minimalny rozmiar to {minSizeLimit}."),
                emptyError: (file_uploader_texts['error_empty'] ? file_uploader_texts['error_empty'] : "Plik nie może być pusty"),
                onLeave: (file_uploader_texts['on_leave'] ? file_uploader_texts['on_leave'] : "Trwa upload pliku. Jeśli wyjdziesz, plik nie zostanie uplodowany") 
            },
            button: null,
            multiple: FileUploaderWrapper.multiple,
            maxConnections: FileUploaderWrapper.maxConnections,
            template: 
                '<div class="qq-uploader">' + 
                    '<div class="qq-upload-drop-area">'+(file_uploader_texts['dropdown_here'] ? file_uploader_texts['dropdown_here'] : 'Upuść pliki tutaj')+'</div>' +
                    '<div class="qq-upload-button"><span>'+FileUploaderWrapper.buttonName+'</span></div>' +
                    '<ul class="qq-upload-list"></ul>' + 
                '</div>',
            // template for one item in file list
            fileTemplate: 
                '<li>' +
                    '<span class="qq-upload-file"></span>' +
                    '<span class="qq-upload-spinner"></span>' +
                    '<span class="qq-upload-size"></span>' +
                    '<a class="qq-upload-cancel" href="#">'+(file_uploader_texts['cancel'] ? file_uploader_texts['cancel'] : 'Anuluj')+'</a>' +
                    '<span class="qq-upload-failed-text">'+(file_uploader_texts['error_uploaded'] ? file_uploader_texts['error_uploaded'] : 'Błąd uploadu')+'</span>' +
                '</li>', 
            // events         
            // you can return false to abort submit,
            onSubmit: function(id, fileName){

            },
            onProgress: function(id, fileName, loaded, total){ 
                $(FileUploaderWrapper.formId+' #file-uploader').parent().parent().removeClass('form-row-error').find('.form-error').html('');
                $(FileUploaderWrapper.formId+' .qq-upload-list').show();
            },
            onComplete: function(id, fileName, responseJSON){
                if (responseJSON['success'] === true) {
                    $(FileUploaderWrapper.formId+' .qq-uploader').addClass('qq-uploader-added');
                    $(FileUploaderWrapper.formId+' .qq-uploader .upload-files-list').html('');
                    var files = responseJSON['data'];
                    for (var key in files){
                        var file = files[key];
                        if (!($('#file-'+file['id']).length > 0)){
                            var divItem = 
                                '<li title="'+file['name']+'" class="newLine upload-files-item '+file['typ']+'" id="file-'+file['id']+'">' +
                                    '<span class="file-name"><b>'+file['shortname']+'</b> ('+file['size']+')</span>' +
                                    '<a class="delete-file" onclick="FileUploaderWrapper.deleteFile(\''+file['id']+'\',\''+file['name']+'\')">'+(file_uploader_texts['delete'] ? file_uploader_texts['delete'] : 'Usuń')+'</a>' +
                                '</li>';    
                            $(FileUploaderWrapper.formId+' .qq-uploader .qq-upload-list').append(divItem);
                        }
                    }
                    $(FileUploaderWrapper.formId+' .qq-upload-list li.qq-upload-success').remove();
                    //jesli tylko jeden plik, to po jego prawidlowym dodaniu, ukryj przycisk
                    if (FileUploaderWrapper.maxConnections == 1){
                        $(FileUploaderWrapper.formId+' .form-field.form-file').addClass('disable');
                        $(FileUploaderWrapper.formId+' .qq-upload-button').hide();
                    }
                } else if (responseJSON['error'] && !uploader.getInProgress()){
                    $(FileUploaderWrapper.formId+' #file-uploader .qq-upload-fail').remove();
                    $(FileUploaderWrapper.formId+' #file-uploader').parent().parent().addClass('form-row-error').find('.form-error').html(responseJSON['error']);
                } else {
                    console.log(JSON.stringify(responseJSON));
                }

            },
            onCancel: function(id, fileName){

            },
            showMessage: function(message){ 
                $(FileUploaderWrapper.formId+' #file-uploader').parents('.form-row').addClass('form-row-error').find('.form-error').html(message);
            }                                    
        });
    }
};
