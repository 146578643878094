//=require commons/variables.js 
//=require commons/lib.js
//=require commons/form.js 
//=require commons/ui-forms.js
//=require commons/site.js

var config = { 
    'xs': 480, 
    'sm': 576,
    'md': 768,
    'lg': 992,
    'xl': 123
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
function debounce(func, wait, immediate) {
	var timeout;
	return function() { 
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

$(function() {
    Site.init(); 
});
